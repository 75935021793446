<template>
  <section>
    <div class="container py-10">
      <ul class="grid gap-5 sm:grid-cols-2 lg:grid-cols-4">
        <template v-for="carClass in classes">
          <CarClass :car-class="carClass">
            <div class="flex flex-wrap items-center justify-end gap-5 p-5 mt-5 text-sm bg-gray-50 dark:bg-gray-800">
              <div v-if="detailedPrices" class="w-full">
                <div class="flex flex-wrap pb-1 mb-1 border-b">
                  <strong class="font-medium">Hourly rate (min. 3 hours):</strong>
                  <span class="text-right grow">{{ formatPrice(carClass.priceHour) }}</span>
                </div>
                <div class="flex flex-wrap pb-1 mb-1 border-b">
                  <strong class="font-medium">Daily rate:</strong>
                  <span class="text-right grow">{{ formatPrice(calculatePrice({ carClass: carClass.id, type: 'daily', durationDays: 1 })) }}</span>
                </div>
                <template v-if="Array.isArray(detailedPrices)">
                  <div v-for="price in detailedPrices">
                    <div class="flex flex-wrap pb-1 mb-1 border-b">
                      <strong class="font-medium">{{ price.label }}:</strong>
                      <span class="text-right grow">{{ formatPrice(calculatePrice({ carClass: carClass.id, ...price.orderDraft })) }}</span>
                    </div>
                  </div>
                </template>
                <div v-else class="flex flex-wrap pb-1 mb-1 border-b">
                  <strong class="font-medium">Prague Airport to City centre:</strong>
                  <span class="text-right grow">{{ formatPrice(calculatePrice({ carClass: carClass.id, type: 'oneway', distance: 15 })) }}</span>
                </div>
              </div>
              <template v-else>
                from {{ formatPrice(carClass.priceMin) }}
              </template>
              <UButton label="Book" :to="`/book?class=${carClass.id}`" trailing-icon="i-heroicons-arrow-right-20-solid" />
            </div>
          </CarClass>
        </template>
      </ul>
    </div>
  </section>
</template>

<script setup lang="ts">
const classes = useCarClasses()

const props = defineProps<{
  detailedPrices?: true | {
    label: string
    orderDraft: Order
  }[]
}>()
</script>
